import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import LandingPage from './Landing_components/landing_page';
import EmailList from './EmailList'
import VisitorList from './PageVisits';
import './App.css';

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/jaydevspr-email-list" element={<EmailList/>}/>
        <Route path="/jaydevspr-visitor-list" element={<VisitorList/>}/>
        <Route path="/" element={<LandingPage />} />
      </Routes>
    </Router>
  );
}

export default App;