import React, { useState, useEffect, useCallback } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from './ui/table';
import {
  Card,
  CardContent,
  CardHeader,
  CardTitle,
} from './ui/card';

const BASE_BACKEND_URL = "https://jaydevspr-newletter-bcda756834f4.herokuapp.com";
 
const EmailList = () => {
  const [subscribers, setSubscribers] = useState([]);
  const [pagination, setPagination] = useState({
    total: 0,
    page: 1,
    per_page: 10,
    total_pages: 0,
  });
  const [loading, setLoading] = useState(true); 
  const [error, setError] = useState(null);


  const fetchSubscribers = useCallback(
    async (page = 1) => {
      try {
        setLoading(true);
        const url = `${BASE_BACKEND_URL}/api/newsletter/subscribers?page=${page}&per_page=${pagination.per_page}`;
        console.log('Fetching from:', url);

        const response = await fetch(url, {
          method: 'GET',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
          },
          credentials: 'include'
        });
        
        // Log the raw response for debugging
        const rawText = await response.text();
        console.log('Raw response:', rawText);
        
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        
        // Parse the response text
        const data = JSON.parse(rawText);
        console.log('Parsed data:', data);

        if (!data.data || !Array.isArray(data.data)) {
          throw new Error('Invalid data format received from server');
        }

        setSubscribers(data.data.map(subscriber => ({
          ...subscriber,
          sign_up_date: subscriber.sign_up_date || 'N/A',
          sign_up_time: subscriber.sign_up_time || 'N/A',
          ip_address: subscriber.ip_address || 'N/A',
          user_agent: subscriber.user_agent || 'N/A',
          time_browsed: subscriber.time_browsed || 'N/A',
          email_sent_successfully: subscriber.email_sent_successfully || false
        })));

        setPagination(data.pagination);
      } catch (err) {
        console.error('Error fetching subscribers:', err);
        setError(err.message);
      } finally {
        setLoading(false);
      }
    },
    [pagination.per_page]
  );


  useEffect(() => {
    fetchSubscribers();
  }, [fetchSubscribers]);

  const formatDateTime = (date, time) => {
    if (!date || !time || date === 'N/A' || time === 'N/A') return 'N/A';
    return `${date} ${time}`;
  };

  const formatTimeBrowsed = (timeBrowsed) => {
    if (!timeBrowsed || timeBrowsed === 'N/A') return 'N/A';
    return timeBrowsed;
  };

  if (loading) {
    return (
      <Card className="w-full bg-white">
        <CardContent className="p-6">
          <div className="flex justify-center items-center h-32">
            Loading...
          </div>
        </CardContent>
      </Card>
    );
  }

  if (error) {
    return (
      <Card className="w-full bg-white">
        <CardContent className="p-6">
          <div className="text-red-500 text-center">Error: {error}</div>
        </CardContent>
      </Card>
    );
  }

  return (
    <Card className="w-full bg-white">
      <CardHeader>
        <CardTitle>Newsletter Signups</CardTitle>
      </CardHeader>
      <CardContent>
        <div className="rounded-md border border-gray-200">
          <Table>
            <TableHeader>
              <TableRow>
                <TableHead className="w-[250px] bg-gray-50 text-black">Email</TableHead>
                <TableHead className="w-[200px] bg-gray-50 text-black">
                  Signup Date/Time
                </TableHead>
                <TableHead className="w-[150px] bg-gray-50 text-black">
                  IP Address
                </TableHead>
                <TableHead className="bg-gray-50 text-black">User Agent</TableHead>
                <TableHead className="w-[150px] bg-gray-50 text-black">
                  Time Browsed
                </TableHead>
                <TableHead className="w-[100px] bg-gray-50 text-black">
                  Email Sent
                </TableHead>
              </TableRow>
            </TableHeader>
            <TableBody>
              {subscribers.map((subscriber, index) => (
                <TableRow key={subscriber.sign_up_id || index}>
                  <TableCell className="font-medium text-black">
                    {subscriber.email}
                  </TableCell>
                  <TableCell className="text-black">
                    {formatDateTime(
                      subscriber.sign_up_date,
                      subscriber.sign_up_time
                    )}
                  </TableCell>
                  <TableCell className="text-black">{subscriber.ip_address}</TableCell>
                  <TableCell className="max-w-md truncate text-black">
                    {subscriber.user_agent}
                  </TableCell>
                  <TableCell className="text-black">
                    {formatTimeBrowsed(subscriber.time_browsed)}
                  </TableCell>
                  <TableCell className="text-black">
                    {subscriber.email_sent_successfully ? '✓' : '✗'}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </div>

        <div className="mt-4 flex justify-between items-center">
          <div className="text-sm text-gray-500">
            Total: {pagination.total} subscribers
          </div>
          <div className="flex gap-2">
            <button
              onClick={() => fetchSubscribers(pagination.page - 1)}
              disabled={pagination.page <= 1}
              className="px-3 py-1 border rounded hover:bg-gray-50 disabled:opacity-50"
            >
              Previous
            </button>
            <span className="px-3 py-1">
              Page {pagination.page} of {pagination.total_pages}
            </span>
            <button
              onClick={() => fetchSubscribers(pagination.page + 1)}
              disabled={pagination.page >= pagination.total_pages}
              className="px-3 py-1 border rounded hover:bg-gray-50 disabled:opacity-50"
            >
              Next
            </button>
          </div>
        </div>
      </CardContent>
    </Card>
  );
};

export default EmailList;