import React, { useState, useEffect, useCallback } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from './ui/table';
import {
  Card,
  CardContent,
  CardHeader,
  CardTitle,
} from './ui/card';

const BASE_BACKEND_URL = "https://jaydevspr-newletter-bcda756834f4.herokuapp.com";

const PageVisits = () => {
  const [visits, setVisits] = useState([]);
  const [pagination, setPagination] = useState({
    total: 0,
    page: 1,
    per_page: 10,
    total_pages: 0,
  });
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const fetchVisits = useCallback(
    async (page = 1) => {
      try {
        setLoading(true);
        const response = await fetch(
          `${BASE_BACKEND_URL}/api/visits?page=${page}&per_page=${pagination.per_page}`,
          {
            method: 'GET',
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json',
            }
          }
        );

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = await response.json();
        setVisits(data.data);
        setPagination(data.pagination);
      } catch (err) {
        console.error('Error fetching visits:', err);
        setError(err.message);
      } finally {
        setLoading(false);
      }
    },
    [pagination.per_page]
  );

  useEffect(() => {
    fetchVisits();
  }, [fetchVisits]);

  const formatDateTime = (date, time) => {
    if (!date || !time || date === 'N/A' || time === 'N/A') return 'N/A';
    return `${date} ${time}`;
  };

  if (loading) {
    return (
      <Card className="w-full bg-white">
        <CardContent className="p-6">
          <div className="flex justify-center items-center h-32">
            Loading...
          </div>
        </CardContent>
      </Card>
    );
  }

  if (error) {
    return (
      <Card className="w-full bg-white">
        <CardContent className="p-6">
          <div className="text-red-500 text-center">Error: {error}</div>
        </CardContent>
      </Card>
    );
  }

  return (
    <Card className="w-full bg-white">
      <CardHeader>
        <CardTitle>Page Visits</CardTitle>
      </CardHeader>
      <CardContent>
        <div className="rounded-md border border-gray-200">
          <Table>
            <TableHeader>
              <TableRow>
                <TableHead className="w-[200px] bg-gray-50 text-black">Date/Time</TableHead>
                <TableHead className="w-[150px] bg-gray-50 text-black">IP Address</TableHead>
                <TableHead className="bg-gray-50 text-black">User Agent</TableHead>
                <TableHead className="w-[150px] bg-gray-50 text-black">Time Browsed</TableHead>
              </TableRow>
            </TableHeader>
            <TableBody>
              {visits.map((visit) => (
                <TableRow className="text-black" key={visit.visit_id}>
                  <TableCell className="text-black">
                    {formatDateTime(visit.sign_up_date, visit.sign_up_time)}
                  </TableCell>
                  <TableCell className="text-black">{visit.ip_address}</TableCell>
                  <TableCell className="max-w-md truncate text-black">
                    {visit.user_agent}
                  </TableCell>
                  <TableCell className="text-black">{visit.time_browsed}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </div>

        <div className="mt-4 flex justify-between items-center">
          <div className="text-sm text-gray-500">
            Total: {pagination.total} visits
          </div>
          <div className="flex gap-2">
            <button
              onClick={() => fetchVisits(pagination.page - 1)}
              disabled={pagination.page <= 1}
              className="px-3 py-1 border rounded hover:bg-gray-50 disabled:opacity-50"
            >
              Previous
            </button>
            <span className="px-3 py-1">
              Page {pagination.page} of {pagination.total_pages}
            </span>
            <button
              onClick={() => fetchVisits(pagination.page + 1)}
              disabled={pagination.page >= pagination.total_pages}
              className="px-3 py-1 border rounded hover:bg-gray-50 disabled:opacity-50"
            >
              Next
            </button>
          </div>
        </div>
      </CardContent>
    </Card>
  );
};

export default PageVisits;