import React, { useState, useRef, useEffect, useCallback } from 'react';
// import { useNavigate } from 'react-router-dom';
import { Mail, Loader2 } from 'lucide-react';
import { toast } from 'sonner';
import logo from './assets/jay_logo.png';
import Button from '../ui/button';
import x from './assets/X.png';
import linkedin from './assets/linkedin.png';
import fb from './assets/fb.png';
import ig from './assets/ig.png';
import CircuitBackground from './subcomponents/CircuitBackground';

const BASE_BACKEND_URL = "https://jaydevspr-newletter-bcda756834f4.herokuapp.com"; // Remove trailing slash

const ComingSoonPage = () => {
    const [email, setEmail] = useState('');
    const [status, setStatus] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const startTimeRef = useRef(Date.now());
    const visitRecorded = useRef(false);

    const calculateTimeBrowsed = useCallback(() => {
        const endTime = Date.now();
        const timeSpent = endTime - startTimeRef.current;
        return new Date(timeSpent).toISOString().substr(11, 8); // Format as HH:MM:SS
    }, []);


    const recordVisit = useCallback(async () => {
        if (visitRecorded.current) return;

        try {
            const response = await fetch(`${BASE_BACKEND_URL}/api/visits/record`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                },
                body: JSON.stringify({
                    time_browsed: '00:00:00' // Initial visit time
                })
            });

            if (!response.ok) {
                throw new Error('Failed to record visit');
            }

            visitRecorded.current = true;
            
        } catch (error) {
            console.error('Error recording visit:', error);
        }
    }, []);

    const updateVisitDuration = useCallback(async () => {
        if (!visitRecorded.current) return;

        try {
            await fetch(`${BASE_BACKEND_URL}/api/visits/record`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                },
                body: JSON.stringify({
                    time_browsed: calculateTimeBrowsed()
                })
            });
        } catch (error) {
            console.error('Error updating visit duration:', error);
        }
    }, [calculateTimeBrowsed]);

    useEffect(() => {
        recordVisit();

        const intervalId = setInterval(updateVisitDuration, 60000);

        return () => {
            clearInterval(intervalId);
            if (visitRecorded.current) {
                updateVisitDuration();
            }
        };
    }, [recordVisit, updateVisitDuration]); 

    const validateEmail = (email) => {
        const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return regex.test(email);
    };

    const handleExternalLink = (url) => {
        window.open(url, '_blank', 'noopener,noreferrer');
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        setStatus('');

        if (!validateEmail(email)) {
            toast.error('Por favor ingrese un email válido');
            setStatus('Por favor ingrese un email válido');
            return;
        }

        setIsLoading(true);

        try {
            const response = await fetch(`${BASE_BACKEND_URL}/api/newsletter/subscribe`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                },
                credentials: 'include',
                body: JSON.stringify({
                    email: email.toLowerCase().trim(),
                    time_browsed: calculateTimeBrowsed()
                }),
            });

            let data;
            try {
                data = await response.json();
                if (!response.ok) {
                    throw new Error(data.message || 'Server error');
                }
            } catch (error) {
                if (error.name === 'TypeError') {
                    toast.error('No se pudo conectar al servidor');
                    return;
                }
                throw error;
            }

            if (!response.ok) {
                switch (response.status) {
                    case 429:
                        throw new Error('Has excedido el límite de intentos. Por favor intente más tarde.');
                    case 400:
                        throw new Error(data?.message || 'Por favor verifique su email e intente nuevamente.');
                    case 500:
                        throw new Error('Error interno del servidor. Por favor intente más tarde.');
                    default:
                        throw new Error(data?.message || 'Hubo un error al procesar su solicitud.');
                }
            }

            if (response.status === 206) {
                toast.warning('Te has registrado exitosamente, pero hubo un problema al enviar el email de confirmación.');
                setStatus('¡Gracias por su interes! El email fue enviado, se puede demorar.');
            } else {
                toast.success('¡Gracias por registrarte! Por favor revisa tu correo electrónico.');
                setStatus('¡Gracias! Por favor revise su correo electrónico.');
            }

            setEmail('');
            startTimeRef.current = Date.now();

        } catch (error) {
            console.error('Error details:', {
                message: error.message,
                stack: error.stack,
                cause: error.cause
            });

            toast.error(error.message || 'Hubo un error. Por favor intente nuevamente.');
            setStatus(error.message || 'Hubo un error. Por favor intente nuevamente.');

        } finally {
            setIsLoading(false);
        }
    };

    return (
        <div className="min-h-screen relative bg-gradient-to-br from-[#162d4e] to-[#0A1930] overflow-hidden">
            {/* Circuit Background */}
            <CircuitBackground />

            {/* White Overlay */}
            <div className="absolute inset-0 bg-white/10 backdrop-blur-xs" />

            {/* Content */}
            <div className="relative z-10 min-h-screen flex items-center justify-center p-4 sm:p-6">
                <div className="w-full max-w-2xl mx-auto text-center px-4">
                    {/* Logo and Company Name */}
                    <div className="mb-8 sm:mb-12">
                        <div className="flex flex-row gap-2 sm:gap-[10px] justify-center items-center sm:mb-[10] md:mb-[65px] ">
                            <img
                                src={logo}
                                alt="Logo"
                                className="w-16 h-16 sm:w-20 md:w-20 sm:h-20 md:h-20 text-gray-600"
                            />
                            <h2 className="text-3xl sm:text-4xl md:text-6xl font-poppins font-bold text-black">
                                JaydevsPR
                            </h2>
                        </div>
                    </div>

                    {/* Main Heading */}
                    <h1 className="text-4xl sm:text-6xl md:text-[90px] font-orbitron font-bold text-white 
                                 mt-[-20px] sm:mt-[-40px] md:mt-[-60px] mb-4 sm:mb-[10px]
                                 whitespace-normal sm:whitespace-nowrap">
                        PRONTO 2025
                    </h1>

                    {/* Description */}
                    <p className="text-gray-300 text-base sm:text-lg max-w-lg mx-auto mb-6 sm:mb-8 
                                 px-4 sm:px-0">
                        Estamos cerca de proporcionar todas las herramientas digitales necesarias
                        para impulsar su negocio hacia su próxima etapa. Ingrese su
                        <span className='text-cyan-300 font-semibold'> email </span>
                        si desea conocer mas.
                    </p>

                    {/* Email Subscription Form */}
                    <form onSubmit={handleSubmit} className="mb-12 sm:mb-20">
                        <div className="flex flex-col sm:flex-row gap-3 sm:gap-4 justify-center 
                                      items-center max-w-md mx-auto px-4 sm:px-0">
                            <div className="relative flex-grow w-full">
                                <input
                                    type="email"
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                    placeholder="Ingrese su email"
                                    className="w-full px-4 py-2 rounded-lg bg-white/50 border 
                                             border-cyan-400/30 text-gray-800 placeholder-gray-500 
                                             focus:outline-none focus:border-cyan-400 transition-colors
                                             text-sm sm:text-base"
                                    disabled={isLoading}
                                />
                                <Mail className="absolute right-3 top-2.5 h-4 w-4 sm:h-5 sm:w-5 text-gray-500" />
                            </div>

                            <Button
                                type="submit"
                                disabled={isLoading}
                                className="bg-cyan-500 hover:bg-cyan-600 text-white px-4 sm:px-6 py-2 
                                         rounded-lg transition-colors w-full sm:w-auto flex 
                                         items-center justify-center gap-2 text-sm sm:text-base"
                            >
                                {isLoading ? (
                                    <>
                                        <Loader2 className="h-4 w-4 animate-spin" />
                                        Enviando...
                                    </>
                                ) : (
                                    'Someter'
                                )}
                            </Button>
                        </div>

                        {status && (
                            <p className={`mt-2 text-xs sm:text-sm ${status.includes('error') || status.includes('Por favor')
                                ? 'text-green-600'
                                : status.includes('demorar')
                                    ? 'text-green-600'
                                    : 'text-green-600'
                                }`}>
                                {status}
                            </p>
                        )}
                    </form>

                    {/* Social Links */}
                    <div className="flex justify-center gap-3 sm:gap-4 mb-8 sm:mb-12">
                        <Button
                            variant="ghost"
                            size="icon"
                            onClick={() => handleExternalLink('https://twitter.com/JaydevsPR')}
                            className="hover:bg-gray-100/50 transition-colors duration-300 ease-in-out
                                     h-8 w-8 sm:h-10 sm:w-10"
                            aria-label="Follow us on Twitter"
                        >
                            <img src={x} alt="X" className="w-4 h-4 sm:w-5 sm:h-5" />
                        </Button>

                        <Button
                            variant="ghost"
                            size="icon"
                            onClick={() => handleExternalLink('https://www.linkedin.com/company/jaydevspr')}
                            className="hover:bg-gray-100/50 transition-colors duration-300 ease-in-out
                                     h-8 w-8 sm:h-10 sm:w-10"
                            aria-label="Connect with us on LinkedIn"
                        >
                            <img src={linkedin} alt="LinkedIn" className="w-4 h-4 sm:w-5 sm:h-5" />
                        </Button>

                        <Button
                            variant="ghost"
                            size="icon"
                            onClick={() => handleExternalLink('https://www.facebook.com/profile.php?id=100092192868481')}
                            className="hover:bg-gray-100/50 transition-colors duration-300 ease-in-out
                                     h-8 w-8 sm:h-10 sm:w-10"
                            aria-label="Like us on Facebook"
                        >
                            <img src={fb} alt="Facebook" className="w-4 h-4 sm:w-5 sm:h-5" />
                        </Button>

                        <Button
                            variant="ghost"
                            size="icon"
                            onClick={() => handleExternalLink('https://www.instagram.com/jaydevs.pr/')}
                            className="hover:bg-gray-100/50 transition-colors duration-300 ease-in-out
                                     h-8 w-8 sm:h-10 sm:w-10"
                            aria-label="Follow us on Instagram"
                        >
                            <img src={ig} alt="Instagram" className="w-4 h-4 sm:w-5 sm:h-5" />
                        </Button>
                    </div>

                    {/* Copyright and Additional Info */}
                    <div className="text-center space-y-3 sm:space-y-4">
                        <div className="text-gray-400 text-xs sm:text-sm">
                            copyright © {new Date().getFullYear()} JaydevsPR
                        </div>

                        <div className="text-gray-300 text-[10px] sm:text-xs max-w-md mx-auto px-4">
                            Transformando negocios a través de soluciones digitales innovadoras
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ComingSoonPage;
